<template>
  <div>
    <Titlebar>
      <h5 slot="title" class="title is-4 is-marginless">{{ $t('nav.viltrapport') }}</h5>
    </Titlebar>

    <div class="default-spacing is-paddingless-top">
      <article v-if="selectedDistrict !== null" class="message is-success">
        <div class="message-body">
          <span v-html="$t('viltrapport.showingQuotasForDistrict', { name: selectedDistrict.name })"></span>
        </div>
      </article>

      <ViltrapportDistrictPicker />

      <InlineLoader v-if="isLoading" :dark="true" />

      <div v-else>
        <ViltrapportRestrictions />

        <div v-if="quotas.quotas.length > 0" class="columns is-multiline">
          <ViltrapportQuota
            v-for="species in quotas.quotas"
            :key="species.speciesId"
            :species="species">
          </ViltrapportQuota>
        </div>

        <Alert
          v-else
          type="info"
          :message="$t('viltrapport.noQuota')">
        </Alert>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import ViltrapportRestrictions from './ViltrapportRestrictions.vue'
import ViltrapportDistrictPicker from './ViltrapportDistrictPicker.vue'
import ViltrapportQuota from './ViltrapportQuota.vue'

export default {
  components: {
    ViltrapportRestrictions,
    ViltrapportDistrictPicker,
    ViltrapportQuota
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      districts: 'huntarea/viltrapport/getDistricts',
      quotas: 'huntarea/viltrapport/getQuotas',
      selectedDistrict: 'huntarea/viltrapport/getSelectedDistrict'
    }),

    isLoading () {
      return this.quotas === null || this.districts == null
    }
  },

  watch: {
    districts () {
      // If the user refreshes loading from created() wont' work
      // and we have to wait for the value that is already loading
      this.getQuota()
    }
  },

  created () {
    if (this.districts !== null) {
      this.getQuota()
    }
  },

  methods: {
    getQuota () {
      this.$store.dispatch('huntarea/viltrapport/getQuota')
    }
  }
}
</script>

<style scoped>
table.quota-table tr td {
  width: 33%;
}
</style>
