<template>
  <div class="viltrapport-restrictions">
    <Heading size="5" :text="$t('viltrapport.restrictions')" />

    <div class="box">
      <span v-if="quotas.restrictions !== null" class="text-is-preformatted">{{ quotas.restrictions }}</span>

      <span v-else class="has-text-grey">
        {{ $t('viltrapport.noRestrictionsSpecified') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      quotas: 'huntarea/viltrapport/getQuotas'
    })
  }
}
</script>

<style scoped>
.viltrapport-restrictions {
  margin-bottom: 1.5rem;
}
</style>
