<template>
  <div v-if="districts !== null">
    <div v-if="districts.length > 1" class="field push-bottom">
      <label class="label">{{ $t('viltrapport.district') }}</label>

      <div v-for="district in districts" :key="district.id" class="control">
        <label class="radio">
          <input
            v-model="districtId"
            type="radio"
            name="foobar"
            :value="district.id">
          {{ district.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      districts: 'huntarea/viltrapport/getDistricts',
      selectedDistrict: 'huntarea/viltrapport/getSelectedDistrict'
    }),

    districtId: {
      get () {
        return this.selectedDistrict !== null ? this.selectedDistrict.id : null
      },

      set (districtId) {
        const i = this.districts.findIndex(district => district.id === districtId)
        if (i !== -1) {
          this.$store.dispatch('huntarea/viltrapport/setSelectedDistrict', this.districts[i])
        }
      }
    }
  }
}
</script>
