<template>
  <div class="column is-half">
    <Heading size="5" :text="species.name" />

    <Table :fullwidth="true" class="quota-table">
      <TableHeader>
        <TableHeaderCell>{{ $t('viltrapport.type') }}</TableHeaderCell>
        <TableHeaderCell>{{ $t('viltrapport.allocated') }}</TableHeaderCell>
        <TableHeaderCell>{{ $t('viltrapport.animalsLeft') }}</TableHeaderCell>
      </TableHeader>

      <TableContent>
        <TableRow v-for="(quota, i) in species.quotas" :key="i">
          <TableCell>{{ quota.name }}</TableCell>

          <TableCell class="has-text-grey">
            <span v-if="quota.isFree">{{ $t('viltrapport.noLimitQuota') }}</span>
            <span v-else>{{ quota.quota }}</span>
          </TableCell>

          <TableCell>
            <span v-if="quota.isFree">{{ $t('viltrapport.noLimitQuota') }}</span>
            <span v-else>
              <span v-if="quota.animalsLeft > 0">{{ quota.animalsLeft }}</span>
              <span v-if="quota.animalsLeft === 0" class="tag is-success">{{ $t('viltrapport.quotaFull') }}</span>
              <span v-if="quota.animalsLeft < 0">
                <span class="tag is-danger">{{ $tc('viltrapport.quotaExceeded', Math.abs(quota.animalsLeft), { count: Math.abs(quota.animalsLeft) }) }}</span>
              </span>
            </span>
          </TableCell>
        </TableRow>
      </TableContent>
    </Table>
  </div>
</template>

<script>
export default {
  props: {
    species: {
      type: Object,
      required: true
    }
  }
}
</script>
